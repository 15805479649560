
import { EventBus } from '@/plugins/eventBus'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { Bar } from 'vue-chartjs'
import Vue, { PropType } from 'vue'
import { state } from '@/plugins/state'
import FilesMixin from '@/mixins/FilesMixin.vue'
import { VueConstructor } from 'vue/types/umd'

// Create a new chart component
const BarChart = Vue.extend({
  extends: Bar as any,
  props: {
    chartData: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },
  watch: {
    chartData: {
      handler(oldValue, newValue) {
        // If the first label is different, destroy the old chart and render a new one
        if (oldValue.labels[0] != newValue.labels[0]) {
          if ((this as any)._chart) {
            (this as any)._chart.destroy()
          }
          // Render new chart
          this.$nextTick(() => {
            (this as any).renderChart(this.chartData, this.options)
          })
        }
      },
      deep: true
    }
  },
  mounted() {
    (this as any).renderChart(this.chartData, this.options)
  }
})

interface Space {
  id: number,
  name: string
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FilesMixin>>).extend({
  name: 'SpaceStatistics',
  components: {
    BarChart
  },
  mixins: [
  FilesMixin
  ],
  props: {
    /**
    * Statistics to display
    */
    statsToDisplay: {
      type: Array as PropType<string[]>,
      default: () => [
      'standard_subscription',
      'kiosk_subscription',
      'express_subscription',
      'active_proposition',
      'active_worker',
      'associated_worker',
      'worker_user',
      'order',
      'order_fully_honored',
      'order_partially_honored',
      'order_non_honored',
      'order_quantity',
      'sms_job_proposition',
      'accepted_job_proposition',
      'order_job_taken',
      'dpae_confirmation',
      'availability_request',
      'non_interpreted_answer',
      'availability_request_qualification',
      'availability_request_link_qualification',
      'message_sent',
      'message_received',
      'ocr',
      'availability_history',
      'worker_in_contract',
      'applicant',
      'applicant_jobboard',
      'application',
      'application_jobboard'
      ]
    }
  },
  data: () => ({
    loading: true, // Loading state
    spaces: [] as Array<Space>, // All spaces
    selectedSpaces: [] as Array<Space>, // Selected spaces
    scope: 'week', // Scope of the statistics
    currentDate: new Date(), // Current date
    isAdmin: window.location.href.includes('/admin'), // Check if we are on the admin page
    statsCache: new Map(), // Cache of statistics
    cacheSelectedSpaces: [] as Array<Space> // Cache of selected spaces
  }),
  methods: {
    /**
    * Previous date
    */
    previous() {
      const newDate = new Date(this.currentDate)
      
      if (this.scope === 'year') {
        newDate.setFullYear(newDate.getFullYear() - 1)
      } else if (this.scope === 'month') {
        newDate.setMonth(newDate.getMonth() - 1)
      } else { // week
        newDate.setDate(newDate.getDate() - 7)
      }
      
      this.currentDate = newDate
      this.getStatistics()
    },
    /**
    * Next date
    */
    next() {
      const newDate = new Date(this.currentDate)
      
      if (this.scope === 'year') {
        newDate.setFullYear(newDate.getFullYear() + 1)
      } else if (this.scope === 'month') {
        newDate.setMonth(newDate.getMonth() + 1)
      } else { // week
        newDate.setDate(newDate.getDate() + 7)
      }
      
      this.currentDate = newDate
      this.getStatistics()
    },
    /**
    * Today
    */
    today() {
      this.currentDate = new Date()
      this.getStatistics()
    },
    /**
    * Helper method to get ISO week number
    * Handles leap years and week 53 correctly by using ISO 8601 standard
    */
    getWeekNumber(date: Date): number {
      // Copy date to avoid modifying original
      const target = new Date(date.valueOf())
      
      // ISO week starts on Monday (1), adjust date to get correct week
      const dayNr = (date.getDay() + 6) % 7
      
      // Set to nearest Thursday (ISO weeks are defined by Thursday)
      target.setDate(target.getDate() - dayNr + 3)
      
      // Get first day of year
      const firstThursday = new Date(target.getFullYear(), 0, 1)
      
      // First week's thursday is the first thursday of the year
      if (firstThursday.getDay() !== 4) {
        firstThursday.setMonth(0, 1 + ((4 - firstThursday.getDay()) + 7) % 7)
      }
      
      // Get week number by counting thursdays between target and first thursday
      const weekDiff = (target.valueOf() - firstThursday.valueOf()) / (7 * 24 * 60 * 60 * 1000)
      
      return Math.ceil(weekDiff)
    },
    /**
    * Download XLSX file of selected spaces
    */
    async getXLSX() {
      if (this.selectedSpaces.length == 0) {
        EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.statistics.please_select_one_space'), color: 'error' })
        return
      }
      
      this.loading = true
      let url = (this.isAdmin ? '/v1/admin/spaces/statistics' : '/v1/space/statistics') + '?xlsx=true&year=' + this.selectedYear
      + (this.scope === 'month' ? '&month=' + this.selectedMonth : '')
      + (this.scope === 'week' ? '&week=' + this.selectedWeek : '')
      + '&stats=' + this.statsToDisplay.join(',')
      + '&id=' + this.selectedSpaces.map((space: Space) => space.id).join(',')
      
      await this.launchFileDownload(url)
      
      this.loading = false
    },
    /**
    * Select or unselect all spaces
    */
    selectAllSpaces() {
      if (this.selectedSpaces.length == this.spaces.length) {
        this.selectedSpaces = []
      } else {
        this.selectedSpaces = this.spaces
      }
    },
    /**
    * Fetch spaces statistics and redraw charts
    */
    getStatistics() {
      // Check if no space is selected
      if (this.selectedSpaces.length == 0) {
        EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.statistics.please_select_one_space'), color: 'error' })
        return
      }
      
      // Check if we can use cached data
      if (this.statsCache.has(this.cacheKey)) {
        return
      }
      
      // Fetch statistics
      this.loading = true
      let url = (this.isAdmin ? '/v1/admin/spaces/statistics' : '/v1/space/statistics') + '?year=' + this.selectedYear
      + (this.scope === 'month' ? '&month=' + this.selectedMonth : '')
      + (this.scope === 'week' ? '&week=' + this.selectedWeek : '')
      + '&stats=' + this.statsToDisplay.join(',')
      + '&id=' + this.selectedSpaces.map((space: Space) => space.id).join(',')
      
      axios.get(url)
      .then((response: AxiosResponse) => {
        // Cache all results
        this.statsCache.set(this.cacheKey, response.data)
      })
      .catch((error: AxiosError) => {
        EventBus.$emit('snackbar', { axiosError: error })
      })
      .finally(() => {
        this.loading = false
      })
    }
  },
  watch: {
    scope() {
      this.getStatistics()
    }
  },
  mounted() {
    this.loading = true
    
    // retrieve spaces
    let recalls: Promise<AxiosResponse>[] = []
      
      axios.get(this.isAdmin ? '/v1/admin/spaces?page=1' : '/v1/spaces?page=1&deleted=false')
      .then((response: AxiosResponse) => {
        // store retrieved spaces
        this.spaces = response.data
        // prepare recalls if more pages are available
        let match = /page=(\d+)[^>]*>;\s*rel="last"/m.exec(response.headers.link)
        if (match && match[1] && parseInt(match[1]) > 1) {
          for (let i = 2; i <= parseInt(match[1]); i++) {
            recalls.push(
            axios.get(this.isAdmin ? `/v1/admin/spaces?page=${i}` : `/v1/spaces?page=${i}&deleted=false`)
            )
          }
        }
      })
      .then(() => {
        // execute recalls if any
        Promise.all(recalls)
        .then((results) => {
          results.forEach((response: AxiosResponse) => {
            // concatenate each page retrieved spaces
            this.spaces = this.spaces.concat(response.data)
          })
        }).catch((error: AxiosError) => {
          EventBus.$emit('snackbar', { axiosError: error })
        }).finally(() => {
          const selectedSpace = this.spaces.find(space => space.id == state.agency)
          if (selectedSpace) {
            this.selectedSpaces = [selectedSpace] // select the space of the current agency if it exists
          } else {
            this.selectedSpaces = this.spaces // select all spaces by default
          }
          this.today() // refresh statistics
        })
      }) 
    },
    computed: {
      /**
      * Return the current year
      */
      selectedYear(): number {
        return this.currentDate.getFullYear()
      },
      /**
      * Return the current month
      */
      selectedMonth(): number {
        return this.currentDate.getMonth() + 1
      },
      /**
      * Return the current week number
      */
      selectedWeek(): number {
        return this.getWeekNumber(this.currentDate)
      },
      /**
      * Generate cache key for current request
      */
      cacheKey(): string {
        return `${this.scope}_${this.selectedYear}_${this.scope === 'month' ? this.selectedMonth : ''}${this.scope === 'week' ? this.selectedWeek : ''}_${this.cacheSelectedSpaces.map(s => s.id).sort().join(',')}`
      },
      /**
      * Check if today is present
      */
      isTodayPresent(): boolean {
        if (this.scope === 'year') {
          return this.selectedYear === new Date().getFullYear()
        } else if (this.scope === 'month') {
          return this.selectedYear === new Date().getFullYear() && this.selectedMonth === new Date().getMonth() + 1
        } else { // week
          return this.selectedYear === new Date().getFullYear() && this.selectedWeek === this.getWeekNumber(new Date())
        }
      },
      /**
      * Return icon used on select all spaces v-autocomplete
      */
      selectAllSpacesIcon(): string {
        if (this.selectedSpaces.length == 0) {
          return 'mdi-checkbox-blank-outline'
        } else if (this.selectedSpaces.length == this.spaces.length) {
          return 'mdi-close-box'
        } else {
          return 'mdi-minus-box'
        }
      },
      chartOptions() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          },
          legend: {
            display: true,
            labels: {
              fontColor: this.$vuetify.theme.currentTheme.primary
            }
          }
        }
      }
    }
  })
